export default {
    data () {
        return {
            swiperOptions: {
                autoplay: true,//可选选项，自动滑动
                // 如果需要分页器
                pagination: {
                    el: '.swiper-pagination',
                    bulletActiveClass: 'swiper-pagination-bullet-active',
                    clickable :true,
                },
                // 如果需要前进后退按钮
                navigation: {
                    nextEl: '.carousel-next',
                    prevEl: '.carousel-prev',
                }
            }
        }
    }
}